define(['app', 'elementViewed'], function(app, elementViewed) {


  var papFreeGift = function() {
    var component = {};
    component.elementViewed = elementViewed;

    var _config = {
      selectors: {
        papFreeGiftImage: '.papFreeGift_image',
        papFreeGiftText: '.papFreeGift_text'
      },
      classes: {
      }
    };

    var _init = function(element) {
      component.element = element;
      component.papFreeGiftImage = component.element.querySelector(component.config.selectors.papFreeGiftImage);
      component.papFreeGiftText = component.element.querySelector(component.config.selectors.papFreeGiftText);
      component.bind();
      return component;
    };

    var _bind = function() {
      if(component.papFreeGiftImage) {
        component.papFreeGiftImage.addEventListener('click', component.papFreeGiftImageClicked);
      }

      if(component.papFreeGiftText) {
        component.papFreeGiftText.addEventListener('click', component.papFreeGiftTextClicked);
      }
    };

    var _papFreeGiftImageClicked = function() {
      app.publish('tracking/record', 'Pap | papFreeGiftImage', 'Clicked', 'papFreeGiftImage component');
    };

    var _papFreeGiftTextClicked = function() {
      app.publish('tracking/record', 'Pap | papFreeGiftText', 'Clicked', 'papFreeGiftText component');
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.papFreeGiftImageClicked = _papFreeGiftImageClicked;
    component.papFreeGiftTextClicked = _papFreeGiftTextClicked;
    return component;
  };

  return papFreeGift;
});
